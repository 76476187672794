// ** Logo
import logo from "@src/assets/images/logo/logo-main.png";

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner d-flex flex-column align-items-center justify-content-center">
      <img src={logo} alt="logo" width="100" className="" />
      <div className="loading" style={{ right: "unset", top: "35%" }}>
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};

export default SpinnerComponent;
